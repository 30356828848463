<template>
  <div class="header">
    <ContentContainer class="header-container">
      <img
          v-if="isMobile"
          :src="logoMobilePartner"
          style='object-fit: none'
      />
      <img
          v-else
          :src="logoDesktopPartner"
          style="max-width: 130px;"
      />
      <div class="header__right">
        <LanguageSwitcher/>
        <a :href="langCode.value == 'pl' ? 'https://delayfix.com/' : 'https://delayfix.com/'+langCode.value">
          <img v-if="isMobile" :src="logoMobileDF" alt="">
          <img v-else :src="logoDesktopDF" alt="">
        </a>
      </div>
    </ContentContainer>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n'
import ContentContainer from '@/layout/ContentContainer.vue'
import LanguageSwitcher from '@/components/Base/LanguageSwitcher.vue';
import logoMobileDF from '@/assets/svg/logoMobileDF.svg'
import logoDesktopDF from '@/assets/svg/logoDesktopDF.svg'

const langCode = computed(() => {
  return useI18n().locale
})

const store = useStore()

const logoMobilePartner = computed(() => store.state.pageContent?.mobile_logo?.file ?? '');
const logoDesktopPartner = computed(() => store.state.pageContent?.logo?.file ?? '');

const isMobile = computed(() => window.innerWidth < 1024)

</script>

<style lang="scss" scoped>
.header {
  background-color: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 19, 133, 0.05);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;

  width: 100%;

  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding-inline: 16px;
  }

  &__right {
    display: flex;
    align-items: center;
  }
}
</style>
